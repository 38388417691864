
@import '../../global-components/brand-assets/bw-styles.scss';

.all-products {
  position: relative;
  width: 100%;

  .group-preview {
    position: relative;
    border-radius: $basic-increment * 2;
    padding: $basic-increment;
    aspect-ratio: 1 / 1;

    background: white;

    cursor: pointer;
    
    display: flex;
    flex-direction: column;

    transition: all 160ms $default-easing;
    border: 1px solid $bw-background-grey;

    &:hover {
      box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.05);
      border: 1px solid transparent;
    }
  }

  .product-preview {
    position: relative;
    border-radius: $basic-increment * 2;
    padding: $basic-increment;
    aspect-ratio: 1 / 1;

    cursor: pointer;
    
    display: flex;
    flex-direction: column;

    transition: all 160ms $default-easing;
    border: 1px solid $bw-background-grey;

    .status-pulse {
      animation: breathing;
      animation-duration: 0.5s;
      animation-timing-function: $default-easing;
      animation-direction: alternate;
      animation-fill-mode: backwards;
      animation-iteration-count: 6;
      animation-play-state: running;
    }

    &:hover {
      box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.05);
      border: 1px solid transparent;

      .product-preview-visualiser:not(.no-stl) { 
        transform: scale(1.05);
      }

      .notification-badge {
        transform: scale(1);
      }
    }

    .product-preview-header {
      position: relative;
      
      box-sizing: border-box;
      display: flex;
      justify-content: end;
    }

    .product-preview-visualiser {
      flex: 1;
      border-radius: $basic-increment;
      overflow: hidden;
      margin-bottom: $basic-increment;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 600ms $default-easing 45ms;
    }

    .product-preview-footer {
      box-sizing: border-box;

      .product-title {
        opacity: 0.9;
        font-weight: 600;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes breathing {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}